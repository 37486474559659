'use client';

import Grid from '@mui/material/Grid2';

export default function AuthLayout({ children }: { children: React.ReactNode }) {
  return (
    <Grid container columns={16} height="100%">
      <Grid
        size={{
          xs: 1,
          md: 3,
          lg: 5,
        }}
      />
      <Grid
        display="flex"
        alignItems="center"
        size={{
          xs: 14,
          md: 10,
          lg: 6,
        }}
      >
        {children}
      </Grid>
      <Grid
        size={{
          xs: 1,
          md: 3,
          lg: 5,
        }}
      />
    </Grid>
  );
}
